<template>
  <div class="Groupon content_body">
    <!-- 搜索框 -->
    <div class="nav_header">
      <el-row>
        <el-col :span="20">
          <el-form
            :inline="true"
            size="small"
            class="demo-form-inline"
            @keyup.enter.native="handleSearch"
          >
            <el-form-item label="线上名称">
              <el-input
                v-model="couponName"
                @clear="handleSearch"
                clearable
                placeholder="输入线上名称搜索"
              ></el-input>
            </el-form-item>
            <el-form-item label="状态">
              <el-select
                @change="handleSearch"
                v-model="couponActive"
                placeholder="请选择"
                clearable
              >
                <el-option label="未开始" :value="1"></el-option>
                <el-option label="进行中" :value="2"></el-option>
                <el-option label="已结束" :value="3"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="handleSearch">搜索</el-button>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="4" class="text_right">
          <el-button
            type="primary"
            size="small"
            @click="handleGroupBooking(null, 'new')"
            >新增</el-button
          >
        </el-col>
      </el-row>
    </div>
    <!-- 表格 -->
    <div>
      <el-table
        size="small"
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="light"
      >
        <el-table-column label="活动主图缩略" width="120">
          <template slot-scope="scope">
            <el-image
              style="width: 35px; height: 35px"
              :src="scope.row.ImgURL"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="Title" label="线上名称"></el-table-column>
        <el-table-column prop="offlineType" label="线下类型"></el-table-column>
        <el-table-column
          prop="offlineName"
          label="线下名称"
          width="100px"
        ></el-table-column>
        <el-table-column
          :formatter="formatTime"
          label="活动时间"
        ></el-table-column>
        <el-table-column width="80" label="状态" prop="state"></el-table-column>
        <el-table-column label="操作" width="180px">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="small"
              @click="handleGroupBooking(scope.row, 'edit')"
              v-prevent-click
              >编辑</el-button
            >
            <el-button
              type="primary"
              size="small"
              @click="handleGroupBooking(scope.row, 'copy')"
              v-prevent-click
              >复制</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <div class="pad_10 dis_flex flex_x_end" v-if="paginations.total > 0">
      <el-pagination
        background
        :current-page.sync="paginations.page"
        :layout="paginations.layout"
        :total="paginations.total"
        @current-change="currentChange"
      ></el-pagination>
    </div>
    <!-- 新增拼团活动弹出层 -->
    <el-dialog
      :title="DialogTitle"
      :visible.sync="dialogVisible"
      width="950px"
      @closed="dialogClose"
    >
      <el-tabs v-model="activeName">
        <el-tab-pane label="基础信息" name="0">
          <el-scrollbar class="el_scrollbar_height new">
            <el-form
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              label-width="120px"
              class="demo-ruleForm"
              size="small"
            >
              <el-form-item label="活动商品" prop="tableData">
                <el-button
                  v-if="!ruleForm.tableData.length"
                  @click="selShopClick"
                  class="marbm_10"
                  type="primary"
                  size="small"
                  >选择商品</el-button
                >
                <el-table
                  v-if="ruleForm.tableData.length"
                  :data="ruleForm.tableData"
                  style="width: 90%"
                  size="small"
                >
                  <el-table-column label="商品" width="320">
                    <template slot-scope="scope">
                      <el-image
                        style="
                          width: 35px;
                          height: 35px;
                          vertical-align: middle;
                        "
                        :src="scope.row.Image[0].ImgURL"
                        fit="fit"
                      >
                      </el-image>
                      <span class="marlt_10">{{ scope.row.title }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="GoodsTypeName" label="商品类型">
                  </el-table-column>
                  <el-table-column prop="Price" label="销售价格">
                  </el-table-column>
                  <el-table-column label="操作">
                    <template slot-scope="scope">
                      <el-button type="danger" size="small" @click="deleteGoods"
                        >删除</el-button
                      >
                    </template>
                  </el-table-column>
                </el-table>
              </el-form-item>
              <el-form-item label="活动价格" prop="groupList">
                <div style="width: 100%">
                  <el-table
                    class="activePrice"
                    :data="ruleForm.groupList"
                    style="width: 90%"
                    size="small"
                  >
                    <el-table-column label="参团人数" :width="300">
                      <template slot-scope="scope">
                        <el-input
                          style="width: 100px"
                          v-model="ruleForm.groupList[scope.$index].peopleNum"
                          placeholder="请输入"
                        ></el-input>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="cardName"
                      label="参团价格(元)"
                      width="280"
                    >
                      <template slot-scope="scope">
                        <el-input
                          style="width: 100px"
                          v-model="ruleForm.groupList[scope.$index].Price"
                          placeholder="请输入"
                        ></el-input>
                      </template>
                    </el-table-column>
                    <el-table-column label="操作" width="">
                      <template slot-scope="scope">
                        <el-button
                          type="danger"
                          size="small"
                          @click="delGroup(scope.$index)"
                          >删除</el-button
                        >
                      </template>
                    </el-table-column>
                  </el-table>
                  <div
                    style="
                      height: 50px;
                      line-height: 50px;
                      width: 711px;
                      border-top: unset;
                    "
                    class="text_center border"
                  >
                    <el-button type="primary" size="small" @click="AddGroup"
                      >+ 添加</el-button
                    >
                  </div>
                </div>
              </el-form-item>
              <el-form-item label="活动时间" prop="activityTime">
                <el-date-picker
                  v-model="ruleForm.activityTime"
                  type="datetimerange"
                  align="right"
                  size="small"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :default-time="['08:00:00', '17:00:00']"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="团有效期" prop="groupValidity">
                <el-row>
                  <el-col :span="3">
                    <el-select
                      v-model="ruleForm.groupValidity[0]"
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="(item, index) in 31"
                        :key="index"
                        :label="index"
                        :value="index"
                      >
                      </el-option>
                    </el-select>
                  </el-col>
                  <el-col :span="1" class="text_center"> 天 </el-col>
                  <el-col :span="3">
                    <el-select
                      v-model="ruleForm.groupValidity[1]"
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="(item, index) in 24"
                        :key="index"
                        :label="index"
                        :value="index"
                      >
                      </el-option>
                    </el-select>
                  </el-col>
                  <el-col :span="1" class="text_center"> 时 </el-col>
                  <el-col :span="3">
                    <el-select
                      v-model="ruleForm.groupValidity[2]"
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="(item, index) in 60"
                        :key="index"
                        :label="index"
                        :value="index"
                      >
                      </el-option>
                    </el-select>
                  </el-col>
                  <el-col :span="1" class="text_center"> 分 </el-col>
                  <el-col :span="3">
                    <el-select
                      v-model="ruleForm.groupValidity[3]"
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="(item, index) in 60"
                        :key="index"
                        :label="index"
                        :value="index"
                      >
                      </el-option>
                    </el-select>
                  </el-col>
                  <el-col :span="1" class="text_center"> 秒 </el-col>
                </el-row>
              </el-form-item>
              <el-form-item label="干预成团">
                <el-radio-group v-model="ruleForm.interveneBool">
                  <el-radio :label="false">不干预</el-radio>
                  <el-radio :label="true">干预</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="拼团数量限制">
                <el-col :span="7">
                  <el-radio-group v-model="ruleForm.groupNumLimitBool">
                    <el-radio :label="false">不限制</el-radio>
                    <el-radio :label="true">限制</el-radio>
                  </el-radio-group>
                </el-col>
                <el-col :span="5" v-if="ruleForm.groupNumLimitBool">
                  <el-input
                    placeholder="请输入"
                    v-model="ruleForm.groupNum"
                  ></el-input>
                </el-col>
              </el-form-item>
              <el-form-item label="每人最多购买">
                <el-col :span="7">
                  <el-radio-group v-model="ruleForm.buyLimitBool">
                    <el-radio :label="false">不限制限购</el-radio>
                    <el-radio :label="true">限制限购</el-radio>
                  </el-radio-group>
                </el-col>
                <el-col :span="5" v-if="ruleForm.buyLimitBool">
                  <el-input
                    placeholder="请输入"
                    v-model="ruleForm.buyLimit"
                  ></el-input>
                </el-col>
              </el-form-item>
              <el-form-item label="参团限制">
                <el-radio-group v-model="ruleForm.restrictBool">
                  <el-radio :label="false">所以客户均可参与</el-radio>
                  <el-radio :label="true">指定客户可参与</el-radio>
                </el-radio-group>
                <div>
                  <el-checkbox-group
                    v-if="ruleForm.restrictBool"
                    v-model="ruleForm.checkList"
                  >
                    <el-checkbox
                      v-for="(item, index) in CustomerLevel"
                      :key="index"
                      :label="item.ID"
                    >
                      {{ item.Name }}
                    </el-checkbox>
                  </el-checkbox-group>
                </div>
              </el-form-item>
            </el-form>
          </el-scrollbar>
        </el-tab-pane>
        <el-tab-pane label="上架网店" name="1">
          <el-scrollbar class="el_scrollbar_height new">
            <el-tree
              class="padtp_10"
              show-checkbox
              :expand-on-click-node="false"
              node-key="ID"
              :data="entityList"
              default-expanded-keys="[1]"
              :props="defaultProps"
            >
              <span slot-scope="{ node, data }">
                <span class="font_14">{{ data.EntityName }}</span>
                <el-tag
                  class="marlt_5"
                  type="info"
                  size="mini"
                  v-if="data.IsStore"
                  >门店</el-tag
                >
                <el-tag
                  class="marlt_5"
                  type="info"
                  size="mini"
                  v-if="data.IsWarehouse"
                  >仓库</el-tag
                >
              </span>
            </el-tree>
          </el-scrollbar>
        </el-tab-pane>
      </el-tabs>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="confirm"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 新增选择指定商品弹出层 -->
    <el-dialog
      :title="selShopDialogTitle"
      :visible.sync="selShopDialogVisible"
      width="90%"
      @closed="dialogClose('goods')"
    >
      <!-- 搜索框 -->
      <el-row>
        <el-col :span="22">
          <el-form
            :inline="true"
            size="small"
            class="demo-form-inline"
            @keyup.enter.native="GoodsSearch"
          >
            <el-form-item label="线上名称">
              <el-input
                v-model="OnlineName"
                @clear="GoodsSearch"
                clearable
                placeholder="输入商品线上名称搜索"
              ></el-input>
            </el-form-item>
            <el-form-item label="线下名称">
              <el-input
                v-model="OfflineName"
                @clear="GoodsSearch"
                clearable
                placeholder="输入商品线下名称搜索"
              ></el-input>
            </el-form-item>
            <el-form-item label="商品类型">
              <el-select
                @change="GoodsSearch"
                v-model="GoodsType"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="(item, index) in GoodType"
                  :key="index"
                  :label="item.Name"
                  :value="item.GoodsTypeID"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="线上分类">
              <el-cascader
                clearable
                v-model="CategoryID"
                :options="GoodsCategoryValid"
                :props="{ expandTrigger: 'hover' }"
                @change="GoodsSearch"
              ></el-cascader>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="2">
          <el-button size="small" type="primary" @click="GoodsSearch"
            >搜索</el-button
          >
        </el-col>
      </el-row>
      <el-scrollbar class="el_scrollbar_height goods">
        <!-- 表格 -->
        <div>
          <el-table
            size="small"
            ref="multipleTable"
            :data="selTableData"
            tooltip-effect="light"
          >
            <el-table-column prop="ID" label="主图缩略">
              <template slot-scope="scope">
                <el-image
                  style="width: 35px; height: 35px"
                  :src="scope.row.Image[0].ImgURL"
                  fit="fit"
                >
                </el-image>
              </template>
            </el-table-column>
            <el-table-column
              prop="OnlineName"
              label="线上别名"
            ></el-table-column>
            <el-table-column prop="Price" label="售价（元）"></el-table-column>
            <el-table-column
              prop="CategoryName"
              label="线上分类"
            ></el-table-column>
            <el-table-column prop="Sequence" label="线上排序"></el-table-column>
            <el-table-column
              prop="GoodsTypeName"
              label="线下类型"
            ></el-table-column>
            <el-table-column
              prop="OfflineName"
              label="线下名称"
            ></el-table-column>
            <el-table-column label="操作" width="180px">
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  size="small"
                  @click="selGoods(scope.row)"
                  v-prevent-click
                  >选择</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-scrollbar>
      <!-- 页码 -->
      <div class="pad_10 dis_flex flex_x_end" v-if="paginations.total > 0">
        <el-pagination
          background
          :current-page.sync="Goodspaginations.page"
          :layout="Goodspaginations.layout"
          :total="Goodspaginations.total"
          @current-change="GoodscurrentChange"
        ></el-pagination>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import API from "@/api/Marketing/MarketingPlay/Groupon";
import dateTime from "@/components/js/date";
export default {
  name: "Groupon",
  /**  引入的组件  */
  components: {},
  /**  Vue 实例的数据对象**/
  data() {
    // 拼团类型
    var groupType = (rule, value, callback) => {
      console.log(value);
      console.log(111);
      const index = value.findIndex((item) => {
        if (!item.peopleNum || !item.Price) {
          return true;
        }
      });
      console.log(index);
      if (index != -1) {
        return callback(new Error("请输入活动价格"));
      }
    };
    return {
      couponName: "", //线上名称
      couponActive: "", //状态
      activeName: "0",
      tableData: [],
      paginations: {
        total: "",
        layout: " prev, pager, next, jumper, ->, total, slot",
        page: 1,
      },
      Goodspaginations: {
        total: "",
        layout: " prev, pager, next, jumper, ->, total, slot",
        page: 1,
      },
      DialogTitle: "",
      dialogVisible: false,
      selShopDialogTitle: "",
      selShopDialogVisible: false,
      saveType: "",
      ruleForm: {
        tableData: [],
        groupList: [
          {
            peopleNum: "",
            Price: "",
          },
        ],
        activityTime: [],
        groupValidity: [],
        interveneBool: false,
        groupNumLimitBool: false,
        groupNum: "",
        buyLimitBool: false, //购买上限
        buyLimit: "", //购买上限数量
        restrictBool: false, //参团限制
        checkList: [], //参团限制列表
      },
      rules: {
        tableData: [
          { required: true, message: "请选择商品", trigger: "change" },
        ],
        activityTime: [
          { required: true, message: "请输入活动时间", trigger: "change" },
        ],
        groupValidity: [
          { required: true, message: "请输入团有效期", trigger: "change" },
        ],
        groupList: [{ required: true, validator: groupType, trigger: "blur" }],
      },

      defaultProps: {
        children: "Child",
        label: "EntityName",
      },
      entityList: [
        {
          EntityName: "苏州哲联信息科技有限公司",
          IsStore: false,
          IsWarehouse: false,
          ID: 0,
          Child: [
            {
              EntityName: "珠江店",
              IsStore: true,
              IsWarehouse: false,
              ID: 1,
            },
            {
              EntityName: "万达店",
              IsStore: true,
              IsWarehouse: true,
              ID: 2,
            },
          ],
        },
      ],
      selTableData: [],
      GoodType: [],
      GoodsCategoryValid: [],
      CategoryID: "",
      GoodsType: "",
      OfflineName: "",
      OnlineName: "",
      CustomerLevel: [],
    };
  },
  /**计算属性  */
  computed: {},
  /**  方法集合  */
  methods: {
    // 获取拼团列表
    getBookingList() {
      const that = this;
      let params = {
        PageNum: that.paginations.page,
      };
      let status;
      if (that.couponActive == 1) {
        status = "未开始";
      } else if (that.couponActive == 2) {
        status = "进行中";
      } else if (that.couponActive == 3) {
        status = "已结束";
      }
      if (that.couponName && that.status) {
        params = {
          Title: that.couponName,
          state: status,
          PageNum: that.paginations.page,
        };
      } else if (that.couponName) {
        params.Title = that.couponName;
        params.PageNum = that.paginations.page;
      } else if (status) {
        params.state = status;
        params.PageNum = that.paginations.page;
      }
      API.GetBookingList(params).then((res) => {
        if (res.StateCode == 200) {
          that.tableData = res.List;
          that.paginations.total = res.Total;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 获取商品类型
    getGoodType() {
      API.GoodType().then((res) => {
        if (res.StateCode == 200) {
          this.GoodType = res.Data;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 线上分类 - 排除没有子类的分类
    getAppletGoodsCategoryValid() {
      API.AppletGoodsCategoryValid().then((res) => {
        if (res.StateCode == 200) {
          const arr = [];
          res.Data.forEach((res) => {
            const obj = {
              label: res.Name,
              value: res.CategoryID,
              children: [],
            };
            if (res.Child && res.Child.length) {
              res.Child.forEach((res) => {
                obj.children.push({
                  label: res.Name,
                  value: res.CategoryID,
                });
              });
            }

            arr.push(obj);
          });
          this.GoodsCategoryValid = arr;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 获取会员等级
    getCustomerLevel() {
      const that = this;
      API.CustomerLevel({}).then((res) => {
        if (res.StateCode == 200) {
          console.log(res);
          that.CustomerLevel = res.Data;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 获取商品列表
    getGoodAll() {
      const that = this;
      const params = {
        PageNum: this.Goodspaginations.page,
        OfflineName: this.OfflineName,
        GoodsType: this.GoodsType,
        OnlineName: this.OnlineName,
        CategoryID: this.CategoryID[1],
        Active: true,
      };
      API.GoodAll(params).then((res) => {
        if (res.StateCode == 200) {
          that.selTableData = res.List;
          that.Goodspaginations.total = res.Total;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 拼团搜索
    handleSearch() {
      that.paginations.page = 1;
      this.getBookingList();
    },
    // 商品搜索
    GoodsSearch() {
      this.Goodspaginations.page = 1;
      this.getGoodAll();
    },
    // 拼团列表分页
    currentChange() {
      this.getBookingList();
    },
    // 商品分页
    GoodscurrentChange() {
      this.getGoodAll();
    },
    // 时间
    formatTime(row) {
      if (row.StartDate && row.EndDate) {
        return (
          dateTime.formatDate.format(
            new Date(row.StartDate),
            "YYYY-MM-DD hh:mm:ss"
          ) +
          " " +
          "至" +
          " " +
          dateTime.formatDate.format(
            new Date(row.EndDate),
            "YYYY-MM-DD hh:mm:ss"
          )
        );
      }
    },
    // 打开商品弹出层
    selShopClick() {
      this.selShopDialogTitle = "添加指定商品";
      this.selShopDialogVisible = true;
    },

    // 选择商品
    selGoods(row) {
      this.selShopDialogVisible = false;
      this.ruleForm.tableData.push(row);
    },
    // 删除商品
    deleteGoods() {
      this.ruleForm.tableData = [];
    },
    // 添加团类型
    AddGroup() {
      this.ruleForm.groupList.push({
        peopleNum: "",
        Price: "",
      });
    },

    // 删除拼团类型
    delGroup(index) {
      this.ruleForm.groupList.splice(index, 1);
    },
    // 新增编辑秒杀
    handleGroupBooking(row, type) {
      const that = this;
      that.dialogVisible = true;
      that.saveType = type;
      if (type == "new") {
        that.DialogTitle = "新增拼团";
      } else {
        that.DialogTitle = type == "edit" ? "编辑拼团" : "复制拼团";
        API.GoodInfo([row.CommodityID]).then((res) => {
          const obj = {
            Image: [{ ImgURL: res[0][0].ImgURL }],
            title: res[0][0].Name,
            GoodsTypeName: res[0][0].TypeName,
            Price: res[0][0].Price,
          };
          let arr = [];
          if (row.CustomerLevel) {
            const str = row.CustomerLevel.substr(
              0,
              row.CustomerLevel.length - 1
            );
            arr = str.split(",").map(parseFloat);
          }
          const params = {
            GroupBookingID: row.GroupBookingID,
            tableData: [obj], //商品
            activityTime: [row.StartDate, row.EndDate], //有效时间
            groupList: [
              {
                peopleNum: "",
                Price: "",
              },
            ], //团有效期
            groupValidity: [0, 0, 0, 0],
            interveneBool: row.Intervention, //是否干预
            groupNumLimitBool: row.LimitQuantity ? true : false, //拼团数量限制
            groupNum: row.MaxBuyQauntity,
            buyLimitBool: row.LimitBuy ? true : false, //限制购买
            buyLimit: row.LimitCount, //每人购买数量限制
            restrictBool: row.GroupLimit ? true : false, //参团限制
            checkList: arr, //等级列表
          };
          that.ruleForm = params;
        });
      }
    },
    // 保存处理
    save() {
      const that = this;
      const from = that.ruleForm;
      let ExpiryDate = [];
      for (let i = 0; i < 4; i++) {
        ExpiryDate[i] = from.groupValidity[i] ? from.groupValidity[i] : 0;
      }
      ExpiryDate = ExpiryDate.join(",");
      from.groupValidity;
      const groupList = from.groupList.map((item) => {
        if (item.peopleNum && item.Price) {
          const obj = {
            PersonCount: item.peopleNum,
            Price: item.Price,
          };
          return obj;
        }
      });
      const startDate = dateTime.formatDate.format(
        new Date(that.ruleForm.activityTime[0]),
        "YYYY-MM-DD hh:mm:ss"
      );
      const endDate = dateTime.formatDate.format(
        new Date(that.ruleForm.activityTime[1]),
        "YYYY-MM-DD hh:mm:ss"
      );

      const params = {
        CommodityID: from.tableData[0].GoodsID, //商品id
        Title: from.tableData[0].OnlineName, //标题
        StartDate: startDate, //活动开始时间
        EndDate: endDate, //活动结束时间
        ExpiryDate: ExpiryDate, //团有效期
        Intervention: from.interveneBool, //是否干预
        LimitQuantity: from.groupNumLimitBool, //拼团数量限制
        MaxBuyQauntity: from.groupNum,
        LimitBuy: from.buyLimitBool, //限制购买
        LimitCount: from.buyLimit, //每人购买数量限制
        GroupLimit: from.restrictBool, //参团限制
        levelList: from.checkList, //等级列表
        groupList: groupList,
      };
      if (that.saveType == "eait") {
        params.GroupBookingID = that.ruleForm.GroupBookingID;
      }
      const api =
        that.saveType == "edit" ? "UpdateGroupBooking" : "AddGroupBooking";
      API[api](params).then((res) => {
        if (res.StateCode == 200) {
          that.$message.success({
            message: "添加成功",
            duration: 2000,
          });
        } else {
          that.$message.success({
            message: res.Messaage,
            duration: 2000,
          });
        }
      });
    },
    // 保存
    confirm() {
      const that = this;
      that.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (
            that.ruleForm.groupValidity[0] ||
            that.ruleForm.groupValidity[1] ||
            that.ruleForm.groupValidity[2] ||
            that.ruleForm.groupValidity[3]
          ) {
            if (that.ruleForm.groupNumLimitBool && !that.ruleForm.groupNum) {
              that.$message.error({
                message: "请输入拼团数量",
                duration: 2000,
              });
              return;
            }
            if (that.ruleForm.buyLimitBool && !that.ruleForm.buyLimit) {
              that.$message.error({
                message: "请输入每人最多购买数量",
                duration: 2000,
              });
              return;
            }
            if (that.ruleForm.restrictBool && !that.ruleForm.checkList.length) {
              that.$message.error({
                message: "请选择指定客户",
                duration: 2000,
              });
              return;
            }
            // 验证通过
            that.save();
          } else {
            that.$message.error({
              message: "团有效期无效",
              duration: 2000,
            });
          }
        }
      });
    },
    // 关闭弹出层
    dialogClose(type) {
      const that = this;
      that.$refs.ruleForm.resetFields();
      that.ruleForm = {
        tableData: [],
        groupList: [
          {
            peopleNum: "",
            Price: "",
          },
        ],
        activityTime: [],
        groupValidity: [],
        interveneBool: false,
        groupNumLimitBool: false,
        groupNum: "",
        buyLimitBool: false, //购买上限
        buyLimit: "", //购买上限数量
        restrictBool: false, //参团限制
        checkList: [], //参团限制列表
      };
      that.activeName = "0";
      if (type) {
        that.OnlineName = "";
        that.OfflineName = "";
        that.GoodsType = "";
        that.CategoryID = "";
        that.Goodspaginations.page = 1;
      }
    },
  },
  /** 监听数据变化   */
  watch: {},
  /** 创建实例之前执行函数   */
  beforeCreate() {},
  /**  实例创建完成之后  */
  created() {},
  /**  在挂载开始之前被调用  */
  beforeMount() {},
  /**  实例被挂载后调用  */
  mounted() {
    // 获取拼团列表
    this.getBookingList();
    this.getGoodAll();
    // 获取商品类型
    this.getGoodType();
    // 线上分类 - 排除没有子类的分类
    this.getAppletGoodsCategoryValid();
    this.getCustomerLevel();
  },
  /**  数据更新 之前 调用  */
  beforeUpdate() {},
  /** 数据更新 完成 调用   */
  updated() {},
  /**  实例销毁之前调用  */
  beforeDestroy() {},
  /**  实例销毁后调用  */
  destroyed() {},
};
</script>

<style scoped lang="scss">
.Groupon {
  .el_scrollbar_height.new {
    height: 60vh;
    #container {
      width: 600px;
      height: 75vh;
    }
  }
  .el_scrollbar_height.goods {
    height: 60vh;
    #container {
      width: 600px;
      height: 75vh;
    }
  }
}
</style>
